<template>
  <div class="container">
    <div class="wrap">
      <div class="content">
        <div class="detail-wrap">
          <div class="goback" v-if="isMobile" @click="detailBack(1)"></div>
          <div class="share" v-if="isMobile">
            <a
              href="javascript:window.open('http://twitter.com/home?status='+encodeURIComponent(document.location.href)+' '+encodeURIComponent(document.title),'_blank','toolbar=yes, location=yes, directories=no, status=no, menubar=yes, scrollbars=yes, resizable=no, copyhistory=yes, width=600, height=450,top=100,left=350');void(0)"
            >
              <img src="../../assets/img/new/Twitter.png" alt="" />
            </a>
            <a
              href="https://line.naver.jp/R/msg/text/?' + encodeURIComponent(document.title) + '%0A' + encodeURIComponent(document.location.href)"
              target="_blank"
            >
              <img src="../../assets/img/new/line.png" alt="" />
            </a>
          </div>

          <div
            class="goback"
            v-if="isShowIntroduction && !isMobile"
            @click="detailBack(1)"
          ></div>
          <!-- <div
            class="close"
            v-if="isShowIntroduction && !isMobile"
            @click="detailBack(2)"
          ></div> -->

          <div class="scroll-wrap scrollStyle">
            <div class="title">
              <div class="content-title">
                <div class="content-detail" v-text="articleTitle"></div>
              </div>
              <div class="content-time" v-if="details">
                {{ timeFormat(details.tm_publish_s) }}
              </div>
            </div>
            <div class="detail news-data" v-html="articleContent"></div>
          </div>

          <footer v-if="!isMobile">
            <div class="button-wrap" v-if="isShowIntroduction">
              <div
                v-if="prevDetail.id"
                class="previous"
                @click="readNext(false)"
              ></div>
              <div v-if="nextDetail.id" class="next" @click="readNext(true)"></div>
            </div>
            <div class="share">
              <a
                href="javascript:window.open('http://twitter.com/home?status='+encodeURIComponent(document.location.href)+' '+encodeURIComponent(document.title),'_blank','toolbar=yes, location=yes, directories=no, status=no, menubar=yes, scrollbars=yes, resizable=no, copyhistory=yes, width=600, height=450,top=100,left=350');void(0)"
              >
                <img src="../../assets/img/new/Twitter.png" alt="" />
              </a>
              <!-- <a
                href="https://line.naver.jp/R/msg/text/?' + encodeURIComponent(document.title) + '%0A' + encodeURIComponent(document.location.href)"
                target="_blank"
              >
                <img src="../../assets/img/new/line.png" alt="" />
              </a> -->
              <div class="shareFb" @click="shareFb()">
                <img src="../../assets/img/new/Facebook.png" alt="" />
              </div>
            </div>
          </footer>
        </div>
      </div>
      <footer v-if="isMobile">
        <div class="button-wrap">
          <div
            v-if="prevDetail"
            class="previous"
            @click="readNext(false)"
          ></div>
          <div v-if="nextDetail" class="next" @click="readNext(true)"></div>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
import { getArticle } from "@/api/index";
import { mapMutations } from "vuex";
import { timeFormat, isMobile } from "@/utils/util";

export default {
  name: "NewsDetail",
  data() {
    return {
      isMobile,
      isShowIntroduction: true,
      details: null,
      articleContent: "",
      articleTitle: "",
      articleImg: "",
      articleId: 0,
      prevDetail: "",
      nextDetail: "",
    };
  },
  methods: {
    ...mapMutations(["increment"]),
    escape2Html(str) {
      var arrEntities = { lt: "<", gt: ">", nbsp: " ", amp: "&", quot: '"' };
      return str.replace(/&(lt|gt|nbsp|amp|quot);/gi, function (all, t) {
        return arrEntities[t];
      });
    },
    timeFormat(time) {
      return timeFormat(time * 1000);
    },
    detailBack() {
      // status
      // if (status === 1) {
      // this.$router.back();
      // this.$router.go(-1);
      // location.reload();
      // this.$router.push('/Introduction');
      // } else {
      if (this.$route.path.indexOf("/Introduction")  >= 0 ) {
        this.$router.push("/Introduction");
      } else {
        this.$router.push("/News");
      }
      // }
    },
    readNext(status) {
      let detail = "";
      if (status) {
        detail = this.nextDetail;
      } else {
        detail = this.prevDetail;
      }
      console.log(detail.id);
      if (detail.jump_url) {
        window.open(detail.jump_url, "_blank");
      } else {
        this.getDetail(detail.id);
        this.$router.push(`/NewsDetail/${detail.id}`);
      }
    },
    getDetail(id, content) {
      this.articleId = id;
      getArticle({ id })
        .then((res) => {
          console.log(res.data);
          const { content, title, img } = res.data.details;
          this.details = res.data.details;
          this.articleContent = this.escape2Html(content);
          this.articleTitle = title;
          this.articleImg = img;
          this.prevDetail = res.data.prev;
          this.nextDetail = res.data.next;
        })
        .catch((err) => {
          console.log(err.data, content);
        });
    },
    shareFb() {
      const img = encodeURIComponent(
        "http://res.restargames.jp/2021/1115/rSxK1636955311772.png"
      );
      const link = window.location.href;
      const title = encodeURIComponent(this.articleTitle);
      window.FB.ui(
        {
          display: "dialog",
          method: "share_open_graph",
          action_type: "og.likes",
          // hashtag: "#Testing",
          action_properties: JSON.stringify({
            object: {
              "og:image": img,
              "og:image:secure_url": img,
              "og:image:type": "image/jpeg",
              "og:image:width": 300,
              "og:image:height": 300,
              "og:image:alt": img,
              "og:url": link,
              "og:title": title,
              "og:description":
                "壮大な大冒険MMORPG【山海】、予告編大公開！山を越え、海を渡れ！さあ、出発だ！",
              // "fb:admins": fbadmin,
            },
          }),
        },
        function (response) {
          if (typeof response != "undefined") {
            //Success
          } else {
            //Not Success;
          }
        }
      );
    },
  },
  mounted() {
    this.increment({ status: 10 });
    if (this.$route.path.indexOf("/Introduction")  >= 0 ) {
      this.isShowIntroduction = true;
    }
    console.log(this.$route.params.pathMatch);
    this.getDetail(this.$route.params.pathMatch);

    window.fbAsyncInit = function () {
      //  到这里就说明FB加载成功了，这里我们一般会以事件的形式通知调用者来调用
      window.FB.init({
        appId: 586912215095176,
        status: true,
        autoLogAppEvents: true,
        xfbml: true,
        version: "v3.0",
      });
      window.FB.AppEvents.logPageView();
    };

    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.0";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  },
  destroyed() {
    // this.increment({ status: 1 });
  },
};
</script>

<style lang="scss" scoped>
@media (min-width: 751px) {
  .container {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    background: url(../../assets/img/new/bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    padding-top: 87px;
    box-sizing: border-box;
  }
  header {
    display: flex;
    font-size: 21px;
    color: #15385b;
    line-height: 88px;
    background: #a9d7e7;
    position: relative;
    z-index: 9;
  }
  header > div {
    margin-left: 87px;
  }
  .wrap {
    flex: 1;
    .content {
      height: 826px;
      background: url(../../assets/img/new/newsAll.png);
      background-repeat: no-repeat;
      background-size: 1800px 940px;
      background-position: 0px -120px;
      box-sizing: border-box;
      // padding: 150px 200px 0 288px;
      padding: 123px 158px 0 143px;
    }
  }

  .detail-wrap {
    width: 1523px;
    margin: 0 auto;
    position: relative;
  }

  .title {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    color: #15385b;
    font-size: 26px;
    padding: 20px 0;
    border-bottom: 2px solid #15385b;
    margin-bottom: 36px;
    .content-title {
      display: flex;
      align-items: center;
      width: 80%;

      .content-detail {
        // width: 70%;
        // white-space: nowrap;
        // text-overflow: ellipsis;
        // overflow: hidden;
        // word-break: break-all;
      }
    }
    .icon {
      display: inherit;
      width: 18px;
      height: 18px;
      border: 1px solid #15385b;
      transform: rotate(45deg);
      margin-left: 18px;
      margin-right: 34px;
      span {
        display: inline-block;
        width: 10px;
        height: 10px;
        margin: 4px;
        background: #15385b;
      }
    }
    .icon-next {
      display: flex;
    }
  }

  .scroll-wrap {
    height: 610px;
    overflow-x: auto;
    padding: 20px 180px 0 100px;
  }
  .detail {
    font-size: 18px;
    color: #15385b;
    line-height: 38px;
  }

  .img-wrap {
    // height: 100px;
    // margin-top: 28px;
    margin-bottom: 20px;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  .goback,
  .close {
    position: absolute;
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
  }
  .goback {
    top: 0;
    left: 0;
    width: 90px;
    height: 40px;
    background-image: url(../../assets/img/new/goback.png);
  }
  .close {
    top: 0;
    right: 60px;
    width: 43px;
    height: 41px;
    background-image: url(../../assets/img/new/close.png);
  }

  .next,
  .previous {
    bottom: 24px;
    width: 114px;
    height: 35px;
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
  }

  footer {
    position: relative;
    padding: 0 180px 0 100px;
    box-sizing: border-box;
    width: 1523px;
    margin-top: 9px;
    .button-wrap {
      display: flex;
      justify-content: center;
      // justify-content: space-between;
      width: 280px;
      margin: 0 auto;
    }
    .button-wrap>div:nth-child(2) {
      margin-left: 70px;
    }
  }

  .previous {
    left: 43%;
    background-image: url(../../assets/img/new/previous.png);
  }
  .next {
    right: 43%;
    background-image: url(../../assets/img/new/next.png);
  }
  .share {
    position: absolute;
    top: -22px;
    right: 2px;
    display: flex;
    height: 55px;
    cursor: pointer;
    width: 150px;
    img {
      height: 100%;
      margin-right: 20px;
    }
  }
}
</style>

<style lang="scss" scoped>
@media (max-width: 750px) {
  .container {
    height: 100%;
  }

  .wrap {
    // flex: 1;
    height: 100vh;
    padding-top: 6vh;
    // padding-bottom: 1rem;
    background: url(../../assets/img/app/news/dbg.jpg) no-repeat;
    background-size: 100% 108%;
    background-position: bottom;

    box-sizing: border-box;
  }

  .detail-wrap {
    // width: 76%;
    position: relative;
    padding: 1.6rem 10% 0 14%;
  }

  .title {
    // display: flex;
    // justify-content: space-between;
    // align-items: flex-end;
    color: #15385b;
    font-size: 26px;
    padding: 20px 0;
    border-bottom: 2px solid #15385b;
    margin-bottom: 36px;
    .content-title {
      // display: flex;
      align-items: center;
      // width: 80%;

      .content-detail {
        // width: 70%;
        // white-space: nowrap;
        // text-overflow: ellipsis;
        // overflow: hidden;
        // word-break: break-all;
        font-size: 0.4rem;
      }
    }
    .content-time {
      text-align: right;
      margin: 0.1rem 0;
    }
  }

  .scroll-wrap {
    height: 72vh;
    overflow-x: auto;
    padding: 0.2rem 0.3rem 0 0;
    box-sizing: border-box;
    // padding: 20px 180px 0 100px;
  }
  .detail {
    // font-size: 18px;
    color: #15385b;
    // line-height: 38px;
  }

  .img-wrap {
    // height: 100px;
    // margin-top: 28px;
    margin-bottom: 20px;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  .goback,
  .close,
  .previous,
  .next {
    // position: absolute;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .goback {
    position: absolute;
    top: 0.7rem;
    left: 0.8rem;
    width: 1.5rem;
    height: 0.6rem;
    background-image: url(../../assets/img/app/news/goback.png);
  }
  .next,
  .previous {
    width: 2rem;
    height: 0.6rem;
  }
  .previous {
    background-image: url(../../assets/img/app/news/up.png);
  }
  .next {
    background-image: url(../../assets/img/app/news/down.png);
  }
  .share {
    position: absolute;
    top: 0.5rem;
    right: 0.39rem;
    height: 0.6rem;
    display: flex;
    a {
      display: inline-block;
      height: 0.6rem;
    }
    img {
      height: 100%;
      margin-right: 0.18rem;
    }
  }
  .shareFb {
    height: 0.6rem;
  }

  .button-wrap {
    display: flex;
    justify-content: space-between;
    width: 4.5rem;
    margin: 0.2rem auto 0;
  }
}
</style>
